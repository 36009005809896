import { useThree } from "@react-three/fiber";
import Sketch from "./class";
import { useEffect, useRef } from "react";
import { isMobile } from "react-device-detect";
const ParticleComponent = () => {
  const { scene, gl, camera } = useThree();
  const refInit = useRef(false);
  useEffect(() => {
    if (refInit.current) return;
    refInit.current = true;
    new Sketch({
      dom: gl.domElement,
      scene,
      camera,
      gl,
    });
  }, []);
  useEffect(() => {
    if (isMobile) {
      camera.position.z = 2.2;
      camera.position.y = 0.3;
    } else {
      camera.position.z = 1.5;
      camera.position.y = 0.5;
    }
  }, [isMobile]);
  return null;
};

export default ParticleComponent;
