import { useProgress } from "@react-three/drei";
import { useEffect, useRef, useState } from "react";
import { PulseLoader } from "react-spinners";

const LoadingDiv = ({ setLoadingComplete }) => {
  const totalProgress = 2;
  const { loaded, total } = useProgress();

  // useEffect(() => {
  //   console.log("total", total);
  // }, [total]);
  useEffect(() => {
    // console.log("loaded", loaded);
    // console.log("total", total);
    // const temp1 = (loaded / totalProgress) * 100;
    // setProgress(parseInt(temp1));
    // setLoadingComplete
    if (loaded === totalProgress) {
      setLoadingComplete(true);
    }
  }, [loaded]);
  return (
    <>
      <div
        style={{
          width: "100%",
          height: "100%",
          background: "#1e1e1e",
          zIndex: "100",
          position: "fixed",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <PulseLoader color="#FFFFFF" />
      </div>
    </>
  );
};

export default LoadingDiv;
