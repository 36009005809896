import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";

import vertexShader from "./shaders/vertex.glsl";
import fragmentShader from "./shaders/fragment.glsl";

import simFragment from "./shaders/simFragment.glsl";
import simVertex from "./shaders/simVertex.glsl";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import { MeshSurfaceSampler } from "three/examples/jsm/math/MeshSurfaceSampler.js";

import suzanne from "./letter_w.glb?url";

function lerp(a, b, n) {
  return (1 - n) * a + n * b;
}

const loadImage = (path) => {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.crossOrigin = "Anonymous"; // to avoid CORS if used with Canvas
    img.src = path;
    img.onload = () => {
      resolve(img);
    };
    img.onerror = (e) => {
      reject(e);
    };
  });
};

export default class Sketch {
  constructor(options) {
    this.size = 256;
    this.number = this.size * this.size;
    this.container = options.dom;
    // this.scene = new THREE.Scene();
    this.scene = options.scene;

    // this.width = this.container.offsetWidth;
    // this.height = this.container.offsetHeight;
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.raycaster = new THREE.Raycaster();
    this.pointer = new THREE.Vector2();

    // this.renderer = new THREE.WebGLRenderer({
    //   alpha: true,
    //   antialias: true,
    // });
    this.renderer = options.gl;
    // this.renderer.setClearColor(0x222222, 1);
    // this.renderer.setSize(this.width, this.height);
    // this.container.appendChild(this.renderer.domElement);

    // this.camera = new THREE.PerspectiveCamera(
    //   70,
    //   this.width / this.height,
    //   0.01,
    //   10
    // );
    this.camera = options.camera;
    // this.camera.position.z = 1.5;
    // this.camera.position.y = 0.5;
    // this.controls = new OrbitControls(this.camera, this.renderer.domElement);
    // this.controls.target.set(0, 0.5, 0);
    // this.controls.update();

    this.time = 0;
    this.setupSettings();
    this._position = new THREE.Vector3();
    this.loader = new GLTFLoader();
    Promise.all([this.loader.loadAsync(suzanne)]).then(([model]) => {
      // this.data1 = this.getPointsOnSphere()
      // this.data2 = this.getPointsOnSphere()
      this.suzanne = model.scene.children[0];
      this.sampler = new MeshSurfaceSampler(this.suzanne).build();
      this.data1 = this.getPointsOnSuzanne();
      this.data2 = this.getPointsOnSuzanne();
      // this.scene.add(this.suzanne);
      // this.getPixelDataFromImage(t1);
      this.mouseEvents();
      this.setupFBO();
      this.addObjects();
      this.setupResize();
      this.render();
    });
  }

  setupSettings() {
    // this.settings = {
    //   progress: 0,
    // };
    // this.gui = new GUI();
    // this.gui.add(this.settings, "progress", 0, 1, 0.01).onChange((val) => {
    //   this.simMaterial.uniforms.uProgress.value = val;
    // });
  }

  getPointsOnSphere() {
    const data = new Float32Array(4 * this.number);
    for (let i = 0; i < this.size; i++) {
      for (let j = 0; j < this.size; j++) {
        const index = i * this.size + j;

        // generate point on a sphere
        let theta = Math.random() * Math.PI * 2;
        let phi = Math.acos(Math.random() * 2 - 1); //
        // let phi = Math.random()*Math.PI; //
        let x = Math.sin(phi) * Math.cos(theta);
        let y = Math.sin(phi) * Math.sin(theta);
        let z = Math.cos(phi);

        data[4 * index] = x;
        data[4 * index + 1] = y;
        data[4 * index + 2] = z;
        data[4 * index + 3] = (Math.random() - 0.5) * 0.01;
      }
    }

    let dataTexture = new THREE.DataTexture(
      data,
      this.size,
      this.size,
      THREE.RGBAFormat,
      THREE.FloatType
    );
    dataTexture.needsUpdate = true;

    return dataTexture;
  }
  getPointsOnSuzanne() {
    const data = new Float32Array(4 * this.number);
    for (let i = 0; i < this.size; i++) {
      for (let j = 0; j < this.size; j++) {
        const index = i * this.size + j;

        this.sampler.sample(this._position);

        data[4 * index] = this._position.x;
        data[4 * index + 1] = this._position.y;
        data[4 * index + 2] = this._position.z;
        // data[4 * index + 3] = (Math.random() - 0.5) * 0.01;
        data[4 * index + 3] = 1.0;
      }
    }

    let dataTexture = new THREE.DataTexture(
      data,
      this.size,
      this.size,
      THREE.RGBAFormat,
      THREE.FloatType
    );
    dataTexture.needsUpdate = true;

    return dataTexture;
  }

  mouseEvents() {
    this.planeMesh = new THREE.Mesh(
      // new THREE.SphereGeometry(1, 30, 30),
      this.suzanne.geometry,
      new THREE.MeshBasicMaterial()
    );
    // this.dummy = new THREE.Mesh(
    //   new THREE.SphereGeometry(0.002, 32, 32),
    //   new THREE.MeshNormalMaterial()
    // );
    // this.scene.add(this.dummy);
    // window.addEventListener("mousemove", (e) => {
    //   this.pointer.x = (e.clientX / this.width) * 2 - 1;
    //   this.pointer.y = -(e.clientY / this.height) * 2 + 1;
    //   this.raycaster.setFromCamera(this.pointer, this.camera);

    //   const intersects = this.raycaster.intersectObjects([this.planeMesh]);
    //   if (intersects.length > 0) {
    //     this.dummy.position.copy(intersects[0].point);
    //     this.simMaterial.uniforms.uMouse.value = intersects[0].point;
    //   } else {
    //     this.simMaterial.uniforms.uMouse.value = new THREE.Vector3(
    //       9999,
    //       9999,
    //       9999
    //     );
    //   }
    // });
    function handleMove(e) {
      let clientX, clientY;

      if (e.touches) {
        // Evento de touch
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        // Evento de mouse
        clientX = e.clientX;
        clientY = e.clientY;
      }

      this.pointer.x = (clientX / this.width) * 2 - 1;
      this.pointer.y = -(clientY / this.height) * 2 + 1;
      this.raycaster.setFromCamera(this.pointer, this.camera);

      const intersects = this.raycaster.intersectObjects([this.planeMesh]);
      if (intersects.length > 0) {
        // this.dummy.position.copy(intersects[0].point);
        this.simMaterial.uniforms.uMouse.value = intersects[0].point;
      } else {
        this.simMaterial.uniforms.uMouse.value = new THREE.Vector3(
          9999,
          9999,
          9999
        );
      }
    }

    window.addEventListener("mousemove", handleMove.bind(this));
    window.addEventListener("touchmove", handleMove.bind(this));
    window.addEventListener("touchend", () => {
      this.simMaterial.uniforms.uMouse.value = new THREE.Vector3(
        9999,
        9999,
        9999
      );
    });
  }

  setupResize() {
    window.addEventListener("resize", this.resize.bind(this));
  }

  setupFBO() {
    // create data Texture
    const data = new Float32Array(4 * this.number);
    for (let i = 0; i < this.size; i++) {
      for (let j = 0; j < this.size; j++) {
        const index = i * this.size + j;
        data[4 * index] = lerp(-0.5, 0.5, j / (this.size - 1));
        data[4 * index + 1] = lerp(-0.5, 0.5, i / (this.size - 1));
        data[4 * index + 2] = 0;
        data[4 * index + 3] = 1;
      }
    }

    this.positions = new THREE.DataTexture(
      data,
      this.size,
      this.size,
      THREE.RGBAFormat,
      THREE.FloatType
    );
    this.positions.needsUpdate = true;

    // create FBO scene
    this.sceneFBO = new THREE.Scene();
    this.cameraFBO = new THREE.OrthographicCamera(-1, 1, 1, -1, -2, 2);
    this.cameraFBO.position.z = 1;
    this.cameraFBO.lookAt(new THREE.Vector3(0, 0, 0));

    let geo = new THREE.PlaneGeometry(2, 2, 2, 2);
    this.simMaterial = new THREE.MeshBasicMaterial({
      color: 0xff0000,
      wireframe: true,
    });
    this.simMaterial = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        uMouse: { value: new THREE.Vector3(99999, 99999, 99999) },
        uProgress: { value: 0 },
        uTime: { value: 0 },
        uCurrentPosition: { value: this.data1 },
        uOriginalPosition: { value: this.data1 },
        uOriginalPosition1: { value: this.data2 },
      },
      vertexShader: simVertex,
      fragmentShader: simFragment,
    });
    this.simMesh = new THREE.Mesh(geo, this.simMaterial);
    this.sceneFBO.add(this.simMesh);

    this.renderTarget = new THREE.WebGLRenderTarget(this.size, this.size, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType,
    });

    this.renderTarget1 = new THREE.WebGLRenderTarget(this.size, this.size, {
      minFilter: THREE.NearestFilter,
      magFilter: THREE.NearestFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType,
    });
  }

  resize() {
    // this.width = this.container.offsetWidth;
    // this.height = this.container.offsetHeight;
    this.width = window.innerWidth;
    this.height = window.innerHeight;

    this.renderer.setSize(this.width, this.height);
    this.camera.aspect = this.width / this.height;

    this.camera.updateProjectionMatrix();
  }

  addObjects() {
    this.geometry = new THREE.BufferGeometry();
    const positions = new Float32Array(this.number * 3);
    const uvs = new Float32Array(this.number * 2);
    for (let i = 0; i < this.size; i++) {
      for (let j = 0; j < this.size; j++) {
        const index = i * this.size + j;

        positions[3 * index] = j / this.size - 0.5;
        positions[3 * index + 1] = i / this.size - 0.5;
        positions[3 * index + 2] = 0;
        uvs[2 * index] = j / (this.size - 1);
        uvs[2 * index + 1] = i / (this.size - 1);
      }
    }
    this.geometry.setAttribute(
      "position",
      new THREE.BufferAttribute(positions, 3)
    );
    this.geometry.setAttribute("uv", new THREE.BufferAttribute(uvs, 2));

    this.material = new THREE.MeshNormalMaterial();

    this.material = new THREE.ShaderMaterial({
      uniforms: {
        time: { value: 0 },
        // uTexture: { value: new THREE.TextureLoader().load(texture) },
        uTexture: { value: this.positions },
      },
      vertexShader: vertexShader,
      fragmentShader: fragmentShader,
      // depthWrite: false,
      // depthTest: false,
      // transparent: true,
    });

    this.mesh = new THREE.Points(this.geometry, this.material);
    this.scene.add(this.mesh);
  }

  render() {
    if (!this.renderer) return;
    this.time += 0.05;

    this.material.uniforms.time.value = this.time;

    this.renderer.setRenderTarget(this.renderTarget);
    this.renderer.render(this.sceneFBO, this.cameraFBO);

    this.renderer.setRenderTarget(null);
    this.renderer.render(this.scene, this.camera);

    // swap render targets
    const tmp = this.renderTarget;
    this.renderTarget = this.renderTarget1;
    this.renderTarget1 = tmp;

    this.material.uniforms.uTexture.value = this.renderTarget.texture;
    this.simMaterial.uniforms.uCurrentPosition.value =
      this.renderTarget1.texture;
    this.simMaterial.uniforms.uTime.value = this.time;
    this.width = window.innerWidth;
    this.height = window.innerHeight;
    window.requestAnimationFrame(this.render.bind(this));
  }
}
