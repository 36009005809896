import { Canvas } from "@react-three/fiber";
import ParticleComponent from "./Particles/ParticleComponent";
import { OrbitControls, Stats } from "@react-three/drei";
import { Bloom, EffectComposer } from "@react-three/postprocessing";
import { Leva, useControls } from "leva";
import LoadingDiv from "./Loading/LoadingDiv";
const Experience = () => {
  // const controlsScene = useControls({
  //   luminanceThreshold: {
  //     value: 0,
  //     min: 0.0,
  //     max: 3.0,
  //     label: "Luminance Threshold",
  //   },
  //   mipmapBlur: { value: true, label: "Mipmap Blur" },
  //   radius: { value: 0.1, min: 0.0, max: 20, label: "Radius" },
  //   intensity: { value: 3, min: 0.0, max: 3.0, label: "Intensity" },
  // });
  const controlsScene = {
    luminanceThreshold: {
      value: 0,
      min: 0.0,
      max: 3.0,
      label: "Luminance Threshold",
    },
    mipmapBlur: { value: true, label: "Mipmap Blur" },
    radius: { value: 0.1, min: 0.0, max: 20, label: "Radius" },
    intensity: { value: 3, min: 0.0, max: 3.0, label: "Intensity" },
  };
  return (
    <Canvas>
      {/* <Leva hidden={true} /> */}
      {/* <Stats /> */}
      <ambientLight />
      <ParticleComponent />
      <EffectComposer multisampling={1} disableNormalPass>
        <Bloom
          luminanceThreshold={controlsScene.luminanceThreshold.value}
          mipmapBlur={false}
          radius={controlsScene.radius.value}
          intensity={controlsScene.intensity.value}
        />
      </EffectComposer>
    </Canvas>
  );
};

export default Experience;
